jQuery(document).ready(function() {
  jQuery(window).scroll(function(){
    if (jQuery(window).scrollTop() >= 80) {
      jQuery('.sticky-header').addClass('fixed');
     }
     else {
      jQuery('.sticky-header').removeClass('fixed');
     }
  });
});

jQuery(document).ready(function() {
  jQuery(window).scroll(function(){
    if (jQuery(window).scrollTop() >= 990) {
      jQuery('.sticky-header').addClass('readbox');
     }
     else {
      jQuery('.sticky-header').removeClass('readbox');
     }
  });

 
});
